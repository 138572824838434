<template>
  <div class="page-wrapper">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="row">
        <div class="col-12">
          <pending-items-interval :title="false" />
        </div>
        <div class="col-xxl-12">
          <div class="page-title">
            <h1>Enrollments Available to Edit</h1>
          </div>
        </div>
        <pending-search @onSearch="searchData" @onClear="clearData" />
      </div>
      <div class="table-card">
        <div class="top-pagination">
          <pagination :rows.sync="enrollmentLists" :filters="filters" />
        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>System ID</th>
                <th>Member</th>
                <th>Plan</th>
                <th>Effective Date</th>
                <th>Rep</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-if="!enrollmentLength" colspan="6" align="center">
                  No records found.
                </td>
              </tr>
              <tr
                :id="item.policy_id"
                v-for="(item, index) in enrollmentLists.records"
                :key="index"
              >
                <td>
                  <strong>
                    <router-link
                      class="route-link"
                      :to="
                        '/member/' +
                        base64Encode(item.policy_id) +
                        '/policy-details'
                      "
                      >{{ item.policy_id }}</router-link
                    >
                  </strong>
                </td>
                <td>
                  <strong
                    ><router-link
                      class="route-link"
                      :to="
                        '/member/' +
                        base64Encode(item.policy_id) +
                        '/policy-details'
                      "
                      >{{ item.Member }}</router-link
                    >
                  </strong>
                </td>
                <td>
                  <ul>
                    <li v-for="(p, index) in plans(item.plan)" :key="index">
                      {{ p }}
                    </li>
                  </ul>
                </td>
                <td>{{ getFormattedDate(item.effective_date) }}</td>
                <td>{{ item.Rep }}</td>
                <td>
                  <div class="action-icons">
                    <router-link
                      :to="
                        '/pending/rep-edit-products/' +
                        base64Encode(item.policy_id)
                      "
                      v-b-tooltip.hover
                      title="View & Edit Details"
                    >
                      <i class="fas fa-info" aria-hidden="true"></i>
                    </router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination :rows.sync="enrollmentLists" :filters="filters" />
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import axios from "axios";
import Helper from "@/resource/Helper";
import PendingSearch from "./partials/PendingSearch.vue";
import PendingItemsInterval from "../Dashboard/partials/PendingItemsInternal.vue"
import moment from "moment";
export default {
  name: "Enrollments24hr",
  components: {
    PendingSearch,
    PendingItemsInterval
  },
  data: () => ({
    enrollmentLists: [],
    planLists: [],
    filters: { search: "", page_size: 10 },
    breadcrumbItems: [{ title: "Enrollments 24hr", active: true }],
  }),
  mounted() {
    this.getData();
  },
  methods: {
    async getData(filters = null) {
      let url;
      url = this.$endpoint.GET_24HRS_ENROLLMENT;
      if (filters) {
        let filterUrl = Helper.objToUrlParams(filters);
        url = `${url}?${filterUrl}`;
      }
      let loader = this.$loading.show();
      await axios
        .get(url)
        .then((res) => {
          this.enrollmentLists = res.data;
        })
        .finally(() => {
          loader.hide();
        });
    },
    plans(plan) {
      return plan.split(", ");
    },
    base64Encode(policy_id) {
      return btoa(policy_id);
    },
    getFormattedDate(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    clearData() {
      this.filters.search = "";
      this.getData(this.filters);
    },
    searchData(query) {
      this.filters.search = query;
      this.getData(this.filters);
    },
  },
  computed: {
    enrollmentLength() {
      return this.enrollmentLists?.records?.length;
    },
  },
};
</script>
<style src="@/assets/css/pending-details.css"></style>